export const formatSkillsForSelect = skills => {
  const skills_set = skills.map(skill => ({
    ...skill,
    value: skill.name,
    label: skill.name,
  }))
  return skills_set
}

export const selectValueFromId = (list, id) => {
  if (!id) return null
  const selected = list?.filter(
    value => value?.id?.toString() === id?.toString()
  )
  let return_value = {}
  if (selected?.length > 0) {
    return_value = {
      ...selected?.[0],
      name: selected?.[0]?.name,
      label: selected?.[0]?.label,
    }
  }
  return return_value
}

export const formatDataForSelect = (data, key) => {
  let data_set = []
  if (!key) {
    data_set = data?.map(datum => ({
      ...datum,
      value: datum.id,
      label: datum.name,
    }))
  } else {
    data_set = data?.map(datum => ({
      ...datum,
      value: datum.id,
      label: datum[key],
    }))
  }
  return data_set
}
