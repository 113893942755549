import React, { useContext, useEffect, useState } from "react"

import { navigate, Link } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"

import { GlobalAuthContext } from "../../context/authContext"

import SEO from "../seo"
import "./candidate-profile.scss"
import Header from "../header/header"
import Spinner from "../spinner/spinner"
import BreadcrumbComponent from "../breadcrumb/breadcrum"
import CopyRight from "../footer/copy-right"
import { checkNavigable } from "../../utils/checkNavigable"
import { CANDIDATE_COUNTS } from "../shared-actions/countActions"
import ProfileDataFetcher from "./profile-data"
import { CANDIDATE_PERSONAL_DATA_FETCH } from "../candidate-profile/actions/personalAction"
import { setRedirect } from "../../utils/setRedirect"
import { GET_CONFIG_DATA } from "../shared-actions/get-config-data"
import { backdropAndUnscroll } from "../../utils/backdropAndUnscroll"
import LandingPageFooter from "../landing-page-footer"

const CandidateProfileLayout = ({
  active,
  children,
  title,
  menu,
  sidebarMenu,
  activeSidebar,
  location,
}) => {
  const { state, dispatch } = useContext(GlobalAuthContext)

  const currentPage = {
    name: "Fill in the details to complete your profile!",
  }
  const home = [
    {
      name: "Home",
      link: "/",
    },
  ]
  const [counts, setCounts] = useState({ notification: 0 }) // eslint-disable-line no-unused-vars

  const [isMenuOpen, setMenuOpen] = useState(false)
  const [wholePageLoading, setWholePageLoading] = useState(true)

  const handleHamClick = value => {
    let val = !isMenuOpen
    if (value === 0) val = false
    backdropAndUnscroll(!val)
    setMenuOpen(val)
  }
  const backdropHandler = value => {
    backdropAndUnscroll(value)
  }
  const fetchData = async () => {
    if (
      state?.isAuthenticated &&
      state?.user?.role === 2 &&
      state?.hasProfile &&
      state?.isEmailVerified
    ) {
      if (!state?.config) {
        await GET_CONFIG_DATA(() => {}, dispatch)
      }
      await CANDIDATE_COUNTS(setCounts, dispatch)
      setWholePageLoading(false)
    } else if (state?.isAuthenticated && !state?.isEmailVerified) {
      navigate("/verify-email")
    } else if (
      state?.isAuthenticated &&
      state?.user?.role === 2 &&
      state?.isEmailVerified &&
      !state?.hasProfile
    ) {
      checkForValidity()
    } else if (state?.isAuthenticated && state?.user?.role === 3) {
      navigate("/")
    } else if (!localStorage?.token) {
      navigate("/")
    }
  }

  const checkForValidity = async () => {
    const res = await CANDIDATE_PERSONAL_DATA_FETCH(
      state?.user?.id,
      {},
      () => {},
      () => {},
      dispatch
    )
    if (res?.data?.personal?.verified) {
      setWholePageLoading(false)
    } else {
      if (
        location?.pathname === "/candidate/personal" ||
        location?.pathname === "/candidate/professional" ||
        location?.pathname === "/candidate/education"
      ) {
        setWholePageLoading(false)
      } else {
        setRedirect(dispatch, location?.pathname + location?.search)
        navigate("/candidate/personal")
      }
    }
  }

  useEffect(() => {
    fetchData()
    backdropHandler(true)
  }, [state.hasProfile, state.isAuthenticated]) //eslint-disable-line react-hooks/exhaustive-deps

  if (wholePageLoading) {
    return <Spinner />
  } else {
    return (
      <>
        {state.isAuthenticated ? (
          <>
            <SEO title={title} />
            <Header
              hide={true}
              siteTitle={title}
              isMenuOpen={isMenuOpen}
              handleHamClick={handleHamClick}
            />
            <BreadcrumbComponent
              pages={!!state?.hasProfile ? home : []}
              currentPage={
                !!state?.hasProfile ? { name: title, link: "" } : currentPage
              }
            />
            <section className="candidate-profile">
              <Container>
                <Row>
                  <Col md="9 m-auto" className=" profile-border">
                    <ProfileDataFetcher />
                    <div className="candidate-profile__wrapper">
                      <Row>
                        <Col>
                          <ul className="step-tab-list d-flex justify-content-between mb-2">
                            {menu.map((menuItem, index) => {
                              return (
                                <li
                                  key={index}
                                  className={
                                    active === menuItem.name ? "active" : ""
                                  }
                                >
                                  {state.isAuthenticated &&
                                    (state.hasProfile ||
                                    checkNavigable(menuItem.name) ? (
                                      <Link
                                        className="profile_link_allowed "
                                        to={`${menuItem.link}`}
                                      >
                                        {menuItem.name}
                                      </Link>
                                    ) : (
                                      <span className="profile_link_notallowed ">
                                        {menuItem.name}
                                      </span>
                                    ))}
                                </li>
                              )
                            })}
                          </ul>
                        </Col>
                      </Row>
                      <div className="form-container ">{children}</div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            {/* <LandingPageFooter hasProfile={state?.hasProfile} /> */}
          </>
        ) : (
          <Spinner />
        )}
      </>
    )
  }
}
export default CandidateProfileLayout
