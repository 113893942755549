import React from "react"
import DatePicker from "react-datepicker"

import "./input-text-box.scss"

const Datepicker = props => {
  const handleChange = e => {
    if (props?.maxDate < e) alert("errror")
    props.onChange(e)
  }
  return (
    <div className={props.className}>
      <div>
        <label htmlFor={props.name}>{props.label}</label>
      </div>
      <DatePicker
        showYearDropdown={props.showYearDropdown}
        showMonthDropdown={props.showMonthDropdown}
        // class=
        className={props.class}
        name={props.name}
        customInput={<CustomInput placeholderText={props.placeholderText} />}
        onCalendarOpen={props.onFocus}
        maxDate={!!props.maxDate ? new Date(props.maxDate) : new Date()}
        minDate={props.minDate}
        selected={props.selected}
        onChange={handleChange}
        placeholderText={props.placeholderText}
        dateFormat={props.dateFormat}
      />
    </div>
  )
}

const CustomInput = ({ placeholderText, onClick, value }) => {
  return (
    <div className="position-relative">
      <input
        onClick={onClick}
        className="form-input"
        type="text"
        value={value}
        placeholder={placeholderText}
      />
      <span
        onClick={onClick}
        aria-hidden={true}
        className="input-icon position-absolute"
      >
        <i className="fa fa-calendar-alt " />
      </span>
    </div>
  )
}

export default Datepicker