import React from "react"

import Image from "react-bootstrap/Image"

import ArrowDown from "../../images/icons/wdn-arrow-down.png"

import "./wdn-input-box.scss"

const InputBox = props => {
  const handleChange = e => {
    props.onChange(e.target.value)
  }
  const decideClassName = () => {
    const initial = props.value
      ? props.styles
      : `${props.styles} fade-color-out`
    const disabled = props.disabled ? "disabled-state" : ""
    return initial + " " + disabled
  }
  const field = props.key_value

  if (props.type === "select") {
    return (
      <div className={`form-group selectdiv `}>
        {props.label && <label>{props.label}</label>}
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select
          className={decideClassName()}
          disabled={props.disabled}
          onFocus={props.onFocus}
          style={{ width: props.width }}
          onChange={handleChange}
          value={props.value || props.optionHeader}
        >
          <option disabled defaultValue>
            {props.optionHeader}
          </option>
          {props.options &&
            props.options.length > 0 &&
            props.options.map(option => {
              return (
                <option key={option.id} value={option.id}>
                  {option[field]}
                </option>
              )
            })}
        </select>
        <Image src={ArrowDown} alt="wdn-arrow-down" />
      </div>
    )
  }  else if (props.type == "text") {
    return (
      <div className={`form-group ${props.class}`}>
        <label className={props.hideLabel} htmlFor={`${props.name}`}>
          {props.label}
        </label>

        <input
          className={"form-control " + props?.styles}
          type={props.type}
          onChange={handleChange}
          onFocus={props.onFocus}
          pattern={props.pattern}
          value={props.value}
          disabled={props.disabled}
          placeholder={props.placeholder}
          name={props.name}
          hidden={props.hidden}
        />
      </div>
    )
  }else if (props.type === "radio") {
    return (
      <div className={` form-group form-group-radio`}>
        {props.label && <label>{props.label}</label>}
        <div className="radio-list">
          {props?.options?.map(option => (
            <label>
              <input
                type="radio"
                value={option.id}
                name={props.name}
                key={option.value}
                checked={option?.id?.toString() === props?.value?.toString()}
                onChange={props.onChange}
              />
              <span className="ml-2 label">{option.name}</span>
            </label>
          ))}
        </div>
      </div>
    )
  } else {
    return (
      <div className="selectdiv">
        <input
          className={decideClassName()}
          type={props.type || "text"}
          id={props.id}
          value={props.value}
          onFocus={props.onFocus}
          min={props.min}
          placeholder={props.placeholder}
          onChange={props.onChange}
        />
      </div>
    )
  }
}

export default InputBox
