import React, { useState, useEffect, useContext } from "react"

import { navigate } from "gatsby"

import { GlobalAuthContext } from "../../context/authContext"

import { CANDIDATE_STEP_MENU } from "../../constants/profile-menu"
import { SIDEBAR_MENU_STEP } from "../../constants/sidebar-menu"

import { GET_CONFIG_DATA } from "../../components/shared-actions/get-config-data"
import { CANDIDATE_PERSONAL_DATA_FETCH } from "../../components/candidate-profile/actions/personalAction"

import CandidateProfileLayout from "../../components/layout/candidate-profile"
import PersonalForm from "../../components/candidate-profile/form/personal-form"

const PersonalPage = ({ location }) => {
  const [date, setDate] = useState(null)

  const [configData, setConfigData] = useState({})

  const [formState, setFormState] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    contact: "",
    website: "",
    gender: "",
    country: "",
    state: "",
    dob: null,
    city: "",
    address: "",
    cover_letter: "",
    avatar: "",
  })

  const { state, dispatch } = useContext(GlobalAuthContext)

  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    dispatch({ type: "CHANGE_FIRST_IMPRESSION", payload: false })
    try {
      if (state && state.user && state.user.role === 2) {
        if (!state.config) {
          GET_CONFIG_DATA(setConfigData, dispatch)
        } else {
          setConfigData(state.config)
        }
        CANDIDATE_PERSONAL_DATA_FETCH(
          state.user.id,
          formState,
          setFormState,
          setLoading,
          dispatch
        )
      }
    } catch (err) {
      // dispatch({ type: "LOGOUT" })
      navigate("/login")
    }
  }, [state.user, state.config]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CandidateProfileLayout
      menu={CANDIDATE_STEP_MENU}
      sidebarMenu={SIDEBAR_MENU_STEP}
      activeSidebar="Edit Profile"
      active="Personal"
      title="Personal"
      location={location}
    >
      <PersonalForm
        configData={configData}
        formState={formState}
        isLoading={isLoading}
        setFormState={setFormState}
        date={date}
        setDate={setDate}
      />
    </CandidateProfileLayout>
  )
}
export default PersonalPage
