export const getMaxLegalDob = (country_id, state) => {
  const selected_country = state?.config?.country?.filter(
    country => country?.id === country_id
  )
  const legal_age = selected_country?.[0]?.legal_age
  const date = new Date()
  const year = date?.getFullYear() - parseInt(legal_age)
  const month = date?.getMonth()
  const day = date?.getDate()
  return new Date(year, month, day)
}
