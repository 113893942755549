import React, { useState } from "react"
import Select, { components } from "react-select"

import ArrowDown from "../../images/icons/wdn-arrow-down.png"
import BlackCross from "../../images/icons/wdn-black-cross.jpg"

import "./tag-input.scss"

const TagInput = props => {
  const { formState, setFormState } = props
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const onAddition = tag => {
    if (props.useOnChange) {
      props.useOnChange(tag)
      setIsMenuOpen(false)
      return
    }
    setFormState({ ...formState, skills: tag })
    setIsMenuOpen(false)
  }

  const onBlur = () => {
    setIsMenuOpen(false)
  }

  const onFocus = () => {
    setIsMenuOpen(true)
    props.onFocus()
  }

  const handleInputChange = e => {
    if (!!!e) {
      setIsMenuOpen(false)
    } else if (!isMenuOpen) {
      setIsMenuOpen(true)
    } else {
    }
  }

  return (
    <div
      className={
        "form-group-select-container " + (props?.error ? props?.error : "")
      }
    >
      {props?.label && <label>{props.label}</label>}
      <Select
        className={"react-select-tags " + props.styles}
        classNamePrefix="react-select-tags"
        menuIsOpen={isMenuOpen}
        closeMenuOnSelect={props.closeMenuOnSelect}
        isMulti={props?.isMulti}
        onFocus={onFocus}
        onBlur={onBlur}
        isClearable={props.isClearable}
        placeholder={props.placeholder}
        components={{
          DropdownIndicator: DropdownIcon,
          ClearIndicator,
          NoOptionsMessage,
        }}
        onInputChange={handleInputChange}
        onChange={onAddition}
        value={props.value}
        loadingPlaceholder={props.loadingPlaceholder}
        options={props.options}
        isDisabled={props.disabled}
      />
    </div>
  )
}

const NoOptionsMessage = props => {
  if (!!props.selectProps?.loadingPlaceholder && props.options.length < 1)
    return (
      <div className="m-2 text-center">
        {props.selectProps?.loadingPlaceholder}
      </div>
    )

  return <components.NoOptionsMessage {...props} />
}

const DropdownIcon = props => {
  if (props.isDisabled) return null
  return (
    <img
      className="dropdown-arrow pr-3 pl-3"
      src={ArrowDown}
      alt="wdn-arrow-down"
    />
  )
}

const ClearIndicatorIcon = () => {
  return (
    <img
      className="dropdown-arrow pr-3 pl-2"
      src={BlackCross}
      alt="wdn-arrow-down"
    />
  )
}

const ClearIndicator = props => {
  const {
    children = <ClearIndicatorIcon />,
    innerProps: { ref, ...restInnerProps },
  } = props
  return (
    <div {...restInnerProps} ref={ref}>
      <div>{children}</div>
    </div>
  )
}

export default TagInput
