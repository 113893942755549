import React, { useEffect, useState, useContext } from "react"

import { Row, Col } from "react-bootstrap"

import { GlobalAuthContext } from "../../../context/authContext"

import Warning from "../../../images/icons/wdn-warning.png"

import {
  CANDIDATE_COUNTRY_FETCH,
  PERSONAL_FORM_SUBMIT,
  PROFILE_IMAGE_UPLOAD,
} from "../actions/personalAction"

import { GENDER } from "../../../constants/form"

import "./personal-form.scss"
import TextArea from "../../input/textarea"
import Spinner from "../../spinner/spinner"
import InputBox from "../../input/wdn-input-box"
import InputTextBox from "../../input/input-text-box"
import ProfileImageUploader from "../../profile-image/profile-image-uploader"
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css"
import Datepicker from "../../input/datepicker"
import Social from "../../social"
import { getMaxLegalDob } from "../../../utils/getMaxLegalDOB"
import TagInput from "../../input/tag-input"
import { selectValueFromId } from "../../../utils/format-skills-for-select"

const PersonalForm = props => {
  const { setDate, setFormState, formState, isLoading, configData } = props
  const [errors, setErrors] = useState({})
  const [state, setState] = useState([])
  const [city, setCity] = useState([])
  const [isProcessing, setProcessing] = useState(false)

  const stateValue = useContext(GlobalAuthContext)

  const handleFirstNameChange = value => {
    setFormState({ ...formState, first_name: value })
  }

  const handleMiddleNameChange = value => {
    setFormState({ ...formState, middle_name: value })
  }

  const handleLastNameChange = value => {
    setFormState({ ...formState, last_name: value })
  }

  const handleEmailChange = value => {
    setFormState({ ...formState, email: value })
  }

  const handlePhoneChange = value => {
    setFormState({ ...formState, contact: value })
  }

  const handleWebsiteChange = value => {
    setFormState({ ...formState, website: value })
  }

  const handleGenderChange = value => {
    setFormState({ ...formState, gender: value })
  }

  const handleCountryChange = value => {
    setFormState({
      ...formState,
      country: value?.id,
      country_value: value,
      province: null,
      current_address: null,
    })
  }

  const handleCityChange = value => {
    setFormState({
      ...formState,
      permanent_address: value?.id,
      current_address: value?.id,
      current_address_value: value,
    })
  }

  const handleAddressChange = value => {
    setFormState({ ...formState, address: value })
  }

  const handleAboutChange = e => {
    setFormState({ ...formState, cover_letter: e })
  }

  const handleStateChange = value => {
    setFormState({
      ...formState,
      province: value?.id,
      province_value: value,
      current_address: "",
    })
  }

  const handleDateChange = value => {
    setDate(value)
    setFormState({ ...formState, dob: value })
  }

  const handleSave = e => {
    e.preventDefault()
    setProcessing(true)
    if (errors?.profile_image_not_uploaded) {
      setErrors({
        message: "Please select a valid profile picture.",
        profile_image_not_uploaded: errors?.profile_image_not_uploaded
          ? errors?.profile_image_not_uploaded
          : false,
      })
      setProcessing(false)
      return
    }
    try {
      PERSONAL_FORM_SUBMIT(
        formState,
        setErrors,
        setProcessing,
        false,
        stateValue?.dispatch
      )
    } catch (error) { }
  }

  const handleSaveAndContinue = e => {
    e.preventDefault()
    setProcessing(true)
    if (errors?.profile_image_not_uploaded) {
      setErrors({
        message: "Please select a valid profile picture.",
        profile_image_not_uploaded: errors?.profile_image_not_uploaded,
      })
      setProcessing(false)
      return
    }
    try {
      if (formState.avatar || formState.image_file) {
        PERSONAL_FORM_SUBMIT(
          formState,
          setErrors,
          setProcessing,
          true,
          stateValue?.dispatch
        )
      } else {
        setErrors({ avatar: "Avatar is required" })
      }
    } catch (error) { }
  }

  const handleInputFocus = () => {
    setErrors({
      profile_image_not_uploaded: errors?.profile_image_not_uploaded
        ? errors?.profile_image_not_uploaded
        : false,
    })
  }
  useEffect(() => {
    CANDIDATE_COUNTRY_FETCH(formState, setState, setCity)
  }, [formState.country, formState.province]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form
      className="personal-form"
      onSubmit={e => {
        e.preventDefault()
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <Col>
              <ProfileImageUploader
                formState={formState}
                setFormState={setFormState}
                setProcessing={setProcessing}
                setErrors={setErrors}
                Upload={PROFILE_IMAGE_UPLOAD}
                errors={errors}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="4">
              <InputTextBox
                name="first-name"
                class={errors.first_name && "error"}
                value={formState.first_name}
                placeholder="First Name"
                label={
                  <>
                    First Name<span>*</span>
                  </>
                }
                onFocus={handleInputFocus}
                onChange={handleFirstNameChange}
              />
            </Col>
            <Col md="4">
              <InputTextBox
                class={errors.middle_name && "error"}
                name="middle-name"
                value={formState.middle_name}
                onFocus={handleInputFocus}
                onChange={handleMiddleNameChange}
                placeholder="Middle Name"
                label="Middle Name"
              />
            </Col>
            <Col md="4">
              <InputTextBox
                class={errors.last_name && "error"}
                name="last-name"
                value={formState.last_name}
                onFocus={handleInputFocus}
                onChange={handleLastNameChange}
                placeholder="Last Name"
                label={
                  <>
                    Last Name<span>*</span>
                  </>
                }
              />
            </Col>
          </Row>
          <Row>
            {/* <Col lg="4">
              <InputTextBox
                class={errors.email && "error"}
                name="email"
                value={formState.email}
                onFocus={handleInputFocus}
                onChange={handleEmailChange}
                placeholder="Email Address"
                disabled
                label={
                  <>
                    Email<span>*</span>
                  </>
                }
              />
            </Col> */}
            <Col lg="6">
              <InputTextBox
                value={formState.contact}
                class={errors.contact && "error"}
                name="phone"
                onFocus={handleInputFocus}
                onChange={handlePhoneChange}
                placeholder="Mobile"
                label={
                  <>
                    Mobile<span>*</span>
                  </>
                }
              />
            </Col>
            <Col lg="6">
              <InputTextBox
                class={errors.website && "error"}
                name="website"
                value={formState.website}
                onFocus={handleInputFocus}
                onChange={handleWebsiteChange}
                placeholder="Website"
                label="Website"
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <InputBox
                type="select"
                name="gender"
                key_value="name"
                onFocus={handleInputFocus}
                onChange={handleGenderChange}
                styles={
                  errors.gender
                    ? `rounded-border outline-faded error`
                    : `rounded-border outline-faded`
                }
                optionHeader="Select"
                options={GENDER}
                value={formState.gender}
                label={
                  <>
                    Gender<span>*</span>
                  </>
                }
              />
            </Col>
            <Col lg="6">
              <Datepicker
                showYearDropdown
                className={
                  errors.dob
                    ? "form-group  error position-relative"
                    : "form-group position-relative "
                }
                showMonthDropdown
                label={
                  <>
                    Date of Birth(AD)<span>*</span>
                  </>
                }
                class={"form-control rounded-border outline-faded"}
                name="date"
                onFocus={handleInputFocus}
                placeholderText="DD-MM-YYYY"
                selected={formState.dob}
                maxDate={getMaxLegalDob(formState?.country, stateValue.state)}
                onChange={handleDateChange}
                dateFormat="dd-MM-yyyy"
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <TagInput
                name="country"
                styles={` outline-faded`}
                error={errors?.country ? `error` : ""}
                options={configData.country}
                value={selectValueFromId(
                  configData?.country,
                  formState?.country
                )}
                onFocus={handleInputFocus}
                label={
                  <>
                    Country<span>*</span>
                  </>
                }
                placeholder={"Type and Select..."}
                useOnChange={handleCountryChange}
                closeMenuOnSelect
                isClearable
                disabled
              />
            </Col>
            <Col lg="6">
              <TagInput
                name="state"
                styles={` outline-faded`}
                error={errors?.current_address ? `error` : ""}
                options={state}
                value={selectValueFromId(state, formState?.province)}
                onFocus={handleInputFocus}
                disabled={!formState?.country}
                label={
                  <>
                    State<span>*</span>
                  </>
                }
                placeholder={"Type and Select..."}
                useOnChange={handleStateChange}
                closeMenuOnSelect
                isClearable
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <TagInput
                name="district"
                styles={` outline-faded`}
                error={errors?.current_address ? `error` : ""}
                options={city}
                value={selectValueFromId(city, formState?.current_address)}
                onFocus={handleInputFocus}
                disabled={!formState?.province}
                label={
                  <>
                    City<span>*</span>
                  </>
                }
                placeholder={"Type and Select..."}
                useOnChange={handleCityChange}
                closeMenuOnSelect
                isClearable
              />
            </Col>
            <Col lg="6">
              <InputTextBox
                name="address"
                onChange={handleAddressChange}
                onFocus={handleInputFocus}
                class={errors.address && "error"}
                placeholder="Address"
                label={
                  <>
                    Address<span>*</span>
                  </>
                }
                value={formState.address || " "}
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextArea
                autosize
                onChange={handleAboutChange}
                onFocus={handleInputFocus}
                total={
                  stateValue?.state?.config?.about_field_length?.[0]
                    ?.text_field_length
                }
                label={
                  <>
                    About<span>*</span>
                  </>
                }
                class={errors.cover_letter && "error"}
                value={formState.cover_letter}
                placeholder={`Write short description about your education, skills and experience.\n\nTell why Companies should hire you above others.`}
              />
            </Col>
          </Row>
          <Social />
        </>
      )}
      <Row>
        <Col>
          {errors &&
            Object.keys(errors)
              .slice(0, 1)
              .map((key, value) => {
                if (
                  formState.first_name === "" ||
                  formState.last_name === "" ||
                  formState.email === "" ||
                  formState.contact === "" ||
                  formState.gender === "" ||
                  formState.dob === "" ||
                  formState.country === "" ||
                  formState.province === "" ||
                  formState.current_address === ""
                ) {
                  if (key === "profile_image_not_uploaded") return null
                  return (
                    <div
                      key={value}
                      className="alert-block mb-4 d-flex align-items-center"
                    >
                      <img src={Warning} className="mr-3" alt="wdn-warning" />
                      {errors[key]}
                    </div>
                  )
                } else if (
                  formState.cover_letter === "" &&
                  errors?.cover_letter
                ) {
                  if (key === "profile_image_not_uploaded") return null
                  return (
                    <div
                      key={value}
                      className="alert-block mb-4 d-flex align-items-center"
                    >
                      <img src={Warning} className="mr-3" alt="wdn-warning" />
                      About is required.
                    </div>
                  )
                } else {
                  if (key === "profile_image_not_uploaded") return null
                  return (
                    <div
                      key={value}
                      className="alert-block mb-4 d-flex align-items-center"
                    >
                      <img src={Warning} className="mr-3" alt="wdn-warning" />
                      {errors[key]}
                    </div>
                  )
                }
              })}
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: "3", offset: "5" }}>
          <button
            type="submit"
            onClick={handleSave}
            disabled={isProcessing}
            className="btn p-3 my-2 w-100 btn-custom rounded primary"
          >
            {isProcessing ? `Processing` : `Save`}
          </button>
        </Col>
        <Col lg={{ span: "4" }}>
          <button
            type="submit"
            onClick={handleSaveAndContinue}
            disabled={isProcessing}
            className="btn p-3 my-2 w-100 btn-custom rounded primary"
          >
            {isProcessing ? `Processing` : `Save and continue`}
          </button>
        </Col>
      </Row>
    </form>
  )
}

export default PersonalForm
